import React, { useEffect, Suspense } from "react";
const Consent = React.lazy(() => import("./Consent"));

export default () => {
	const [hidden, toggleHidden] = React.useState(true);

	useEffect(() => {
		setTimeout(() => {
			toggleHidden(false);
		}, 1000);
	});
	return hidden || /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent) ? (
		""
	) : (
		<Suspense fallback={<div></div>}>
			<Consent />
		</Suspense>
	);
};
