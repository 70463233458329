import { createMuiTheme } from "@material-ui/core"

const primary = "#212322"
const secondary = "#F74969"
const warning = "#FFC260"
const justwingit = "#FFC260"
const success = "#3CD4A0"
const info = "#ffffff"

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,

      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    info: {
      main: info,
    },
    text: {
      primary: "#222222",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#FCFCFC",
      light: "#ffffff",
    },
  },
  customShadows: {
    widget: "0 2px 5px 0 rgba(164, 173, 186, 0.25)",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        //backgroundColor: "#4a4a4a66",
        zIndex: 0,
      },
      invisible: {
        zIndex: -1,
      },
    },

    MuiPaper: {
      elevation2: {
        boxShadow: "0 2px 5px 0 rgba(164, 173, 186, 0.25)",
      },
      elevation8: {
        boxShadow: "0 2px 10px 0 rgba(164, 173, 186, 0.7)",
      },
      elevation1: {
        boxShadow: "0 2px 10px 0 rgba(164, 173, 186, 0.25)",
      },
    },
    MuiDialog: {
      paper: {
        zIndex: 2,
        borderRadius: 10,
      },
      container: {
        transitionDuration: "125ms !important",
      },
    },
  },
}

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    h1: {
      fontSize: "3rem",
      fontFamily: ["Space Grotesk"],
    },
    h2: {
      fontSize: "2rem",
      fontFamily: ["Space Grotesk"],
    },
    h3: {
      fontSize: "1.64rem",
      fontFamily: ["Space Grotesk"],
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: ["Space Grotesk"],
    },
    h5: {
      fontSize: "1.285rem",
      fontFamily: ["Space Grotesk"],
    },
    h6: {
      fontSize: "1.142rem",
      fontFamily: ["Space Grotesk"],
    },
    button: {
      fontSize: "1.142rem",
      fontFamily: ["Space Grotesk"],
      letterSpacing: "1px",
    },
  },
  ...defaultTheme,
})

export default theme
