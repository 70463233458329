import React, { useEffect, Suspense } from "react";
import ConsentWrapper from "./ConsentWrapper";

export const wrapPageElement = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it

	return (
		<>
			{element}
			<ConsentWrapper />
		</>
	);
};
